import request from "@/utils/request";

function dictTypeFilter(data) {
  return request({
    url: "/show/dict/type/filter.diti",
    method: "post",
    data,
  });
}

function dictFilter(data) {
  return request({
    url: "/show/dict/filter.diti",
    method: "post",
    data,
  });
}

function dictAdd(data) {
  return request({
    url: "/show/dict/add.diti",
    method: "post",
    data,
  });
}

function dictUpdate(data) {
  return request({
    url: "/show/dict/update.diti",
    method: "post",
    data,
  });
}

function dictDel(data) {
  return request({
    url: "/show/dict/del.diti",
    method: "post",
    data,
  });
}

export { dictTypeFilter, dictFilter, dictAdd, dictDel, dictUpdate };
