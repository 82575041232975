<template>
  <div>
    <a-tabs
      style="backgroundcolor: #fff"
      v-model="requestOptions.dictType"
      @change="onDictTypeChange"
    >
      <a-tab-pane
        v-for="item in dictTypeList"
        :key="item.dictType"
        :tab="item.dictType"
      ></a-tab-pane>
    </a-tabs>
    <PagingQueryPage
      ref="PagingQueryPage"
      :queryFormState="queryFormState"
      size="middle"
      :bordered="true"
      rowKey="dictId"
      :columns="columns"
      :pagination="pagination"
      dataUrl="/show/dict/filter.diti"
      :requestOptions="requestOptions"
    >
      <a-button type="primary" icon="plus" slot="action" @click="add"
        >新增</a-button
      >
    </PagingQueryPage>
    <a-modal
      :width="520"
      :visible="modalVisible"
      :title="(isAdd ? '新增' : '编辑') + modalTitle"
      ok-text="确认"
      cancel-text="取消"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="submit"
      @cancel="
        () => {
          modalVisible = false;
        }
      "
    >
      <a-form-model
        ref="modalForm"
        :model="modalFormState"
        :rules="modalRules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-model-item label="字典名称" prop="dictName">
          <a-input v-model="modalFormState.dictName" />
        </a-form-model-item>
        <a-form-model-item label="字典类型" prop="dictType">
          <a-input v-model="modalFormState.dictType" />
        </a-form-model-item>
        <a-form-model-item label="字典值" prop="dictValue">
          <a-input v-model="modalFormState.dictValue" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import PagingQueryPage from "@/components/PagingQueryPage/diti.vue";
import { Modal, notification } from "ant-design-vue";
import { dictTypeFilter, dictAdd, dictDel, dictUpdate } from "@/apis/dict";
export default {
  components: { PagingQueryPage },
  data() {
    return {
      queryFormState: {},
      requestOptions: {
        dictType: "",
      },
      columns: [
        {
          title: "序号",
          dataIndex: "",
          width: 80,
          align: "center",
          fixed: "left",
          scopedSlots: { customRender: "index" },
        },
        {
          title: "ID",
          dataIndex: "dictId",
          width: 200,
          align: "center",
        },
        {
          title: "字典类型",
          dataIndex: "dictType",
          align: "center",
        },
        {
          title: "字典名称",
          dataIndex: "dictName",
          align: "center",
        },
        {
          title: "字典值",
          dataIndex: "dictValue",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "",
          width: 140,
          align: "center",
          fixed: "right",
          customRender: (record) => {
            return (
              <span class="action-group">
                <a-button
                  type="link"
                  onClick={() => {
                    this.edit(record);
                  }}
                >
                  编辑
                </a-button>
                <a-divider type="vertical" />
                <a-button
                  class="delBtn"
                  type="link"
                  onClick={() => {
                    this.del(record);
                  }}
                >
                  删除
                </a-button>
              </span>
            );
          },
        },
      ],
      pagination: {},
      modalVisible: false, // 弹窗状态
      isAdd: true, // 是否新增
      modalTitle: "字典", // 弹窗标题
      modalRules: {
        dictName: [{ required: true, message: "名称必填", trigger: "blur" }],
        dictType: [{ required: true, message: "类型必填", trigger: "blur" }],
        dictValue: [{ required: true, message: "值必填", trigger: "blur" }],
      }, // 弹窗校验规则
      modalFormState: {
        dictId: "idGenerator",
        dictName: null,
        dictType: null,
        dictValue: null,
      }, // 弹窗值
      dictTypeList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      dictTypeFilter({ numOfPage: "1", rowsInPage: "50" }).then((res) => {
        const {
          code,
          data: { resList },
        } = res?.data;
        if (code === "200") {
          this.requestOptions.dictType = resList[0].dictType;
          this.dictTypeList = resList;
        }
      });
    },
    add() {
      this.modalVisible = true;
      this.isAdd = true;
      for (const key in this.modalFormState) {
        if (Object.hasOwnProperty.call(this.modalFormState, key)) {
          this.modalFormState[key] = null;
        }
      }
      this.modalFormState.dictId = "idGenerator";
    },
    edit(record) {
      this.modalVisible = true;
      this.isAdd = false;
      this.modalFormState.dictId = record.dictId;
      for (const key in this.modalFormState) {
        if (Object.hasOwnProperty.call(this.modalFormState, key)) {
          this.modalFormState[key] = record[key];
        }
      }
    },
    submit() {
      this.$refs["modalForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            dictAdd({
              ...this.modalFormState,
            }).then((res) => {
              const { code } = res.data;
              if (code === "200") {
                this.modalVisible = false;
                this.init();
                this.$refs["PagingQueryPage"].query();
                notification.success({
                  message: "新增成功",
                });
              }
            });
          } else {
            dictUpdate({ ...this.modalFormState }).then((res) => {
              const { code } = res.data;
              if (code === "200") {
                this.modalVisible = false;
                this.init();
                this.$refs["PagingQueryPage"].query();
                notification.success({
                  message: "编辑成功",
                });
              }
            });
          }
        }
      });
    },
    del(record) {
      Modal.confirm({
        title: `确定要删除该${this.modalTitle}吗？`,
        content: "注意：此操作无法撤销！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          dictDel({ dictId: record.dictId }).then((res) => {
            const { code } = res.data;
            if (code === "200") {
              this.init();
              this.$refs["PagingQueryPage"].query();
              notification.success({
                message: "删除成功",
              });
            }
          });
        },
      });
    },
    onDictTypeChange() {
      this.$refs.PagingQueryPage.query();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-tabs {
  background-color: #fff;
  .ant-tabs-bar {
    margin: 0;
  }
}
</style>
